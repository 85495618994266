import React from 'react'
import cx from 'classnames';

import isAbsolutePath from 'helpers/isAbsolutePath';
import ModalVideo from '@/components/ui/ModalVideo';
import StyledButton, { StyledButtonVariantsType } from '@/components/ui/StyledButton';
import { ModalVideoOnClick } from '@/components/ui/ModalVideo/types';
import { VariantType } from 'hooks/useVariant';

import { PageScrollActionProps } from './types'
import s from './styles.module.css'

/**
 * Page actions
 * @param props 
 * @returns 
 */
const Action = (props: PageScrollActionProps) => {
  const { text, href, color, variant = "filled", video = false, className } = props

  // Quick check to see if a url is absolute
  const isAbsolute = isAbsolutePath(href)

  const classes = cx(s.action, className)

  const trigger = (onClick: ModalVideoOnClick) => (
    <StyledButton
      color={color as VariantType}
      variant={variant as StyledButtonVariantsType}
      onClick={() => onClick && onClick()}
      size="xl"
      className={classes}>
      {text}
    </StyledButton>

  )

  if (video) return (
    <ModalVideo
      trigger={trigger}
      src={href} />
  )

  return (
    <StyledButton
      as="a"
      href={href}
      size="xl"
      target={isAbsolute ? "_blank" : undefined}
      color={color as VariantType}
      variant={variant as StyledButtonVariantsType}
      className={classes}>
      {text}
    </StyledButton>
  )
}

export default Action
