import React from 'react'

import PageScroll from '@/components/ui/PageScroll'
import Page from '@/components/ui/PageScroll/Page'

const Home = () => (
  <>
    <PageScroll>
      {
        (page) => (
          <Page
            key={page.id}
            id={page.id}
            headline1={page.headline1}
            headline2={page.headline2}
            description={page.description}
            media={page.media}
            actions={page.actions}
            widgets={page.widgets} />
        )
      }
    </PageScroll>
  </>
)

export default Home 
