import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, Variants } from "framer-motion"

import { usePageScroll } from '../PageScrollProvider'
import Action from '../Action/Action'
import Widgets from '../Widgets'
import Video from '../../Video'
import Typography from '../../Typography'
import Image from '../../Image'
import Html from '../../Html'

import s from './styles.module.css'
import { PageScrollPageProps } from './types'

/**
 * Individual page scroll page
 * @param props 
 * @returns 
 */
const Page = (props: PageScrollPageProps) => {
  const { headline1, headline2, description, actions = [], media, widgets = [], id } = props
  const { src, alt, video, fallback } = media

  const { setCurrentPage, createPageId } = usePageScroll()

  const { ref, inView } = useInView({
    rootMargin: '-10px',
    "threshold": 0.5
  })

  useEffect(() => {
    if (inView) {
      setCurrentPage(id)
    }
  }, [id, inView, setCurrentPage])

  const variants: Variants = {
    offscreen: {
      y: 300,
      opacity: 0
    },
    onscreen: {
      y: 50,
      opacity: 1,
      transition: {
        delay: 0.2,
        type: "spring",
        bounce: 0.2,
        duration: 0.8
      }
    }
  };

  return (
    <motion.div
      ref={ref}
      id={createPageId(id)}
      initial="offscreen"
      // This works better than framer motions built in "whileInView" property
      animate={inView && "onscreen"}
      viewport={{ once: true, amount: 0.8 }}
      className={s.section}>
      {video ?
        <Video
          src={src}
          containerClassName={s.video_container}
          className={s.video}
          fallback={fallback}
          background
          playsInline
          autoPlay
          loop
          muted />
        :
        <>
          <div className={s.image_overlay} />
          <Image
            draggable={false}
            rounded={false}
            src={src}
            alt={alt}
            className={s.image}
            sizes="100vw"
            fill />
        </>
      }
      <motion.article
        variants={variants}
        className={s.content}>
        <Typography as="h1" variant="primary" className={s.headline}>
          <span>{headline1}</span>
          {headline2 && <><br /><span>{headline2}</span><br /></>}
        </Typography>

        <Html as='p' className={s.description} html={description} />
        <Widgets widgets={widgets} />
        <div className={s.actionContainer}>
          {
            // eslint-disable-next-line react/no-array-index-key
            actions.map((action, index) => <Action key={index} {...action} />)
          }
        </div>
      </motion.article>
    </motion.div>
  )
}

export default Page
