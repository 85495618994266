import React from 'react'

import { CopiCares, SocialStats } from '@/components/website'

import { PageScrollWidgetFactoryTypes, PageScrollWidgetProps } from './types'

const factory = {
  social: SocialStats,
  copicares: CopiCares
}

/**
 * Dynamically load widgets for pages based on props
 * @param props 
 * @returns 
 */
const Widgets = (props: PageScrollWidgetProps) => {
  const { widgets = [] } = props

  return (
    <>
      {
        widgets.map((widget, index) => {
          const { data, type } = widget

          if (!type) return null

          const Component = factory[type.toLowerCase() as PageScrollWidgetFactoryTypes]

          if (!Component) return null

          // eslint-disable-next-line react/no-array-index-key
          return <Component stats={[]} key={index} {...data} />
        })
      }
    </>
  )
}

export default Widgets
