import React from 'react';

import { HomeLayout } from '@/components/shared/Layout';
import Home from '@/components/website/Home';

// Moved index page into folder structure.
const index = () => <Home />

index.Layout = HomeLayout

export default index;